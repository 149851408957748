<template>
    <div class="c-header">
        <div class="content md:px-12">
            <div class="logo" @click="toHome">
                <img style="cursor: pointer" src="../assets/img/logo.png" alt="" />
            </div>
            <div class="flex items-center md:hidden">
                <div class="menu-head" v-for="(item, index) in menuList" :key="index" v-if="item.isNavPc">
                    <div class="menu-item-box">
                        <span :ref="!item.route ? `menuitem` : 'menulink'" @click="toRoute(item)">{{ item.name }}</span>
                        <div v-if="item.new" class="dot"></div>
                    </div>
                </div>
            </div>
            <div class="connect-select">
                <div class="connect-box isConnected" v-if="address == 'undefined' || (!address && isShowConnect)" @click="goLogin">
                    <span>{{ lang == 'en' ? 'Connect' : '连接钱包' }}</span>
                </div>
                <div class="connect-box LoginConnect" v-if="address !== 'undefined' && address && isShowConnect">
                    <span>{{ address | addressF }}</span>
                </div>
                <a-popover trigger="hover"></a-popover>
                <template v-if="!isShowConnect"></template>
                <a-select class="plac-select md:hidden xm:hidden" dropdownClassName="plac-dropdown" v-model="currentlang">
                    <a-select-option v-for="lang in locales" :key="lang.value">
                        {{ lang.text }}
                    </a-select-option>
                </a-select>
                <i class="iconfont icon-menu text-24 hidden md:inline-block" :class="[visible ? 'text-253bff' : '']" @click="setVisible()"></i>
            </div>
        </div>
        <HeadMenu :show="showMenu" @onClose="onClose" :activeMenu="activeMenu"></HeadMenu>
        <div class="Faucet-entrance-box" v-if="isFaucet && AJcloseTips == 'false'" >
            <div class="open-bridge-campaign" @click.stop>
                <ModelBridgeCampaign @closeModel="onClose"></ModelBridgeCampaign>
            </div>
        </div>
        <transition name="rotate-menu">
            <div class="mobile-header-menu pb-40" v-if="visible">
                <!-- <div class="mobile-header-menuDiv">
                        <p v-for="(item, index) in menuList" @click="toPage(item.route)">
                            <span>{{ item.name }}</span>
                            <RightOutlined />
                        </p>
                    </div> -->
                <a-menu mode="inline" :open-keys.sync="openKeys">
                    <a-sub-menu v-for="(item, index) in menuList" :key="item.key">
                        <template slot="title">
                            <span class="title" @click="toPage(item.route)">
                                <div class="title-box">
                                    <span>{{ item.name }}</span>
                                    <div v-if="item.new" class="dot"></div>
                                </div>
                            </span>
                        </template>
                    </a-sub-menu>
                </a-menu>
                <div class="line"></div>
                <a-menu mode="inline" :open-keys.sync="langList" @click="selectLang">
                    <a-sub-menu key="lang">
                        <template slot="title">
                            <span class="title text-ffffff">
                                {{ lang == 'en' ? 'Current language' : '当前语言' }}
                                <span class="lang-en">{{ currentlang.toUpperCase() }}</span>
                            </span>
                        </template>
                        <a-menu-item v-for="(v, k) in locales" :key="k" class="bg-transparent">
                            <div :class="[v.value === currentlang ? 'lang-selected' : '']" class="flex items-center">
                                {{ v.text }}
                                <img class="ml-15 icon-check invisible" src="https://s3.us-west-1.amazonaws.com/agi.love/img/icon-check.png" alt="" />
                            </div>
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
                <div class="line"></div>
               
            </div>
        </transition>
        <!-- <div class="mask"></div> -->
    </div>
</template>

<script>
import { setAddress, getAddress } from '@/utils/auth.js';
import Button from '@/components/Button.vue';
import { enable, disconnect } from '@/web3utils/base/index.js';
import { mapState } from 'vuex';
import ModelBridgeCampaign from './openShow/ModelBridgeCampaign.vue';
import HeadMenu from './HeadMenu/HeadMennu.vue';
import Cookies from "js-cookie";
export default {
    components: {
        Button,
        ModelBridgeCampaign,
        HeadMenu,
    },
    filters: {
        addressF(v) {
            if (!v) {
                return '-';
            }
            return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, '$1…$2');
        },
    },
    computed: {
        ...mapState(['locales', 'lang', 'address', 'walletBalance','visible']),
        isShowConnect() {
            const arr = ['AllMedalWall', 'Community', 'Data', 'Swap', 'Asset', 'AssetDetail'];
            if (arr.indexOf(this.$route.name) != -1) return true;
            return false;
        },
        menuList() {
            return [
               {
                    name: this.lang == 'en' ? 'Home' : '首页',
                    isNavPc: true,
                    key: 'home',
                    route: 'Home',
                },
                {
                    name: this.lang == 'en' ? 'Pledge' : '质押',
                    isNavPc: true,
                    key: 'package',
                    route: 'AllMedalWall',
                },
                {
                    name: this.lang == 'en' ? 'Swap' : '兑换',
                    isNavPc: true,
                    key: 'swap',
                    route: 'Swap',
                },
                {
                    name: this.lang == 'en' ? 'Community' : '我的社区',
                    isNavPc: true,
                    key: 'community',
                    route: 'Community',
                },
                {
                    name: this.lang == 'en' ? 'Network-wide data' : '全网数据',
                    isNavPc: true,
                    key: 'data',
                    route: 'Data',
                },
                {
                    name: this.lang == 'en' ? 'My assets' : '我的资产',
                    isNavPc: true,
                    key: 'asset',
                    route: 'Asset',
                },
                // {
                //     name: '邀请好友',
                //     isNavPc: true,
                //     key: 'share',
                //     route: 'Share',
                // },
                //  {
                // 		name: '市场',
                // 		isNavPc: true,
                // 		key: 'market',
                // 		children: [
                // 			{ name: '提供算力', key: 'share', route: 'Supply' },
                // 			{ name: '接入算力', key: 'rental', route: 'Access' },
                // 		]
                // 	},
                // {
                // 	name: '生态系统',
                // 	isNavPc: true,
                // 	key: 'ecosystem',
                // 	children: [
                // 		// {
                // 		// 	name: '基金会拨款',
                // 		// 	key: 'grants',
                // 		// 	href: 'https://bpflac.notion.site/BP-FLAC-Grant-Incubation-Program-428225c85a524de5b8ef7bb55cf766fc'
                // 		// },
                // 		{ name: '去中心化应用', key: 'dapp', disabled: true },
                // 		{ name: '开发者', key: 'developer', disabled: true },
                // 	]
                // },
                // {
                // 	name: '网络',
                // 	isNavPc: true,
                // 	new: true,
                // 	key: 'network',
                // 	children: [
                // 		{ name: '测试网', key: 'test', href: 'https://explorer.wodrpc.org/#/index' },
                // 		{ name: 'Forgenet Bridge', key: 'bridge', href: 'https://bridge.wodrpc.org/' },
                // 		{ name: 'Faucet', key: 'faucet', route: 'Faucet' },
                // 	]
                // },
                // {
                // 	name: '治理',
                // 	isNavPc: true,
                // 	key: 'governance',
                // 	children: [
                // 		{ name: '文档', key: 'docs', href: 'https://wmkjcds-organization.gitbook.io/agi/' },
                // 		{ name: '委托', key: 'delegate', href: 'https://explorer.wodrpc.org/#/stake' },
                // 		{ name: '论坛', key: 'forum', disabled: true },
                // 		{ name: '投票', key: 'vote', disabled: true },
                // 	]
                // },
                // {
                // 	name: '社交平台',
                // 	key: 'social',
                // 	isNavPc: true,
                // 	children: [
                // 		{ name: '推特', key: 'twitter', href: 'https://twitter.com/wod_global' },
                // 		{ name: 'Discord', key: 'discord', href: 'https://discord.gg/CscJeyvybG' },
                // 		{ name: 'Medium', key: 'medium', href: 'https://medium.com/@wod_global' },
                // 		{ name: 'Telegram', key: 'telegram', href: 'https://t.me/Wod_Global' },
                // 		{ name: 'Youtube', key: 'youtube', href: 'https://www.youtube.com/@BP-FLAC_2023' },
                // 	]
                // },
                // {
                // 	name: 'Eon',
                // 	key: 'eon',
                // 	isNavPc: false,
                // 	new: true,
                // 	href: 'https://eonchain.xyz',
                // 	children: [
                // 		{ name: 'Eon 网站', key: 'website', href: 'https://eonchain.xyz' },
                // 		{
                // 			name: '交易 Eon',
                // 			key: 'swap',
                // 			href: 'https://wodswap.io/#/swap?use=v2&inputCurrency=0x31cE96Ae990c374A567B3DF38ee4c1D27e630F8C'
                // 		},
                // 		// { name: 'Claim Eon', key: 'claim', route: 'Airdrop', new: true },
                // 	]
                // },
                // {
                // 	name: 'Journey',
                // 	key: 'journey',
                // 	isNavPc: true,
                // 	new: true,
                // 	// route: 'MedalWall',
                // 	children: [
                // 		{ name: 'Medal Wall', key: 'medalwall', route: 'MedalWall' },
                // 		{ name: 'NFT', key: 'nft', route: 'NFT' },
                // 	]
                // }
            ];
        },
        currentlang: {
            get() {
                return this.lang;
            },
            set(v) {
                this.$store.dispatch('setLang', v);
                window.localStorage.setItem('lang', v);
            },
        },
        AJcloseTips(){
            return "false";
            // return Cookies.get('AJcloseTips') || 'false';
        }
        
    },
    inject: ['reload'],
    watch: {
        address(val) {
            console.log('header-address333----', val);
            if (!val) this.$router.push('/');
            if (val) this.init();
        },
    },
    data() {
        return {
            msg: {},
            timeS: '',
            popoverShow: false,
            isFaucet: false,
            openKeys: [],
            langList: ['lang'],
            showMenu: false,
            activeMenu: 0,
        };
    },

    async mounted() {
        this.init();
        const PopupFaucet = sessionStorage.getItem('isPopupFaucet');
        if (!PopupFaucet) {
            if (this.$route.path == '/') {
                this.isFaucet = true;
            }
        }
        // this.$refs.menuitem.forEach((item, index) => {
        //     item.addEventListener('mouseenter', e => {
        //         this.activeMenu = index;
        //         this.showMenu = true;
        //     });
        // });
        // this.$refs.menulink.forEach((item, index) => {
        //     item.addEventListener('mouseenter', e => {
        //         this.showMenu = false;
        //     });
        // });
    },
    methods: {
        setVisible(){
            this.$store.commit('SET_VISIBLE', !this.visible);
        },
        async init() {
            if (this.address) {
                await this.$sleep(1000);
                await this.$sleep(200);
            }
        },
        toRoute(item) {
            if (!item.route || this.$route.name == item.route) return  this.$store.commit('SET_VISIBLE', false);
            this.$router.push({ name: item.route });
        },
        onClose() {
            console.log('0000')
            this.showMenu = false;
            this.isFaucet = false;
        },
        async disconnect() {
            setAddress('');
            this.$store.commit('CLEAR', '');
            disconnect();
            this.$router.push('/');
            this.reload();
            // window.location.reload();
        },

        async goLogin() {
            //获取登录
            let enables = await enable();
            if (!enables.code) {
                this.$message('钱包未登录');
                return;
            }
            this.$store.commit('SET_CONNECT', true);
        },

        selectLang({ item }) {
            console.log('item===>', item);
            this.currentlang = this.locales[item.index].value;
        },
        toPage(name, item = {}) {
            this.$store.commit('SET_VISIBLE', false)
            if (this.$route.name == name) return;
            if (!name) {
                window.open(item.href);
            } else {
                this.$router.push({ name: name });
            }
        },
        toHome() {
            if (this.$route.name == 'Home') return;
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style lang="scss">
.c-header {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #312d3a;
    position: sticky;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0);
    backdrop-filter: blur(20px);
    z-index: 1000;
    z-index: 22222;

    @screen md {
        height: 55px;
        position: sticky;
        left: 0;
        right: 0;
        top: 0;
        background: #000000;
    }

    & > .content {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 75px;
        justify-content: space-between;
        gap: 20px;

        .items-center {
            display: flex;
            gap: 40px;
        }

        @screen md {
            height: 55px;
        }

        .logo {
            display: flex;
            align-items: center;
            img {
                /* width: 168px; */
                height: 30px;
            }
            @screen md {
                img {
                    height: 26px;
                }
            }
        }

        .menu-item {
            font-size: 16px;
            text-decoration: none;
            color: rgba(255, 255, 255, 0.6);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 62px;
                margin-left: 6px;
            }
        }

        .menu-head {
            .menu-item-box {
                display: flex;
                align-items: flex-start;
                gap: 3px;

                img {
                    width: 42px;
                    /* height: 26px; */
                }

                .dot {
                    width: 6px;
                    height: 6px;
                    background-color: #4ce4f9;
                    border-radius: 50%;
                }
            }

            span {
                cursor: pointer;
                color: rgba(255, 255, 255, 0.6);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
            }

            &:hover span {
                color: #fff;
            }
        }

        .select {
            height: 32px;
            background-color: #121821;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 101px;
            border-radius: 30px;
            color: #ffffff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;

            img {
                margin-left: 10px;
            }
        }
    }

    .connect-select {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-left: 30px;
        position: relative;

        .connect-box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            height: 32px;

            border-radius: 30px;
            background: linear-gradient(90deg, #2d41fa 0%, #4ce4f9 100%) !important;
            border: 0 none;
            padding: 0 17px;
            box-shadow: none !important;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                .popover-Box {
                    display: block;
                }
            }

            span {
                white-space: nowrap;
            }

            .img-box {
                margin-left: 10px;
                width: 20px;
                height: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .isConnected {
        }
        .faucet {
            background: linear-gradient(90deg, #2d41fa 0%, #4ce4f9 100%) !important;
        }

        .LoginConnect {
            position: relative;

            background: #111924 !important;
            .disconnect {
                position: absolute;
            }
        }
    }

    .plac-select {
        width: 103px;
        /* margin-left: 17px; */
        color: #ffffff;

        .ant-select-selection {
            border-radius: 30px;
            background-color: #111924;
            border: 0 none;
            padding: 0 20px;
            box-shadow: none !important;
            border: 1px solid transparent;

            .ant-select-selection-selected-value {
                font-weight: bold;
            }

            .ant-select-arrow {
                color: #525765;
                right: 20px;
                top: 42%;
            }
        }

        &.ant-select-focused,
        &:hover {
            .ant-select-selection {
                border: 1px solid #54657a;
            }
        }
    }
    .mobile-header-menuDiv {
        padding-left: 26px;
        padding-right: 26px;
    }
    .mobile-header-menu {
        position: fixed;
        top: 55px;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgb(3, 2, 6);
        z-index: 1000;
        transform-origin: center top;
        padding-top: 20px;

        .ant-menu {
            border-right: none;
            background: rgb(3, 2, 6);
            color: rgba(255, 255, 255, 0.8);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .ant-menu-submenu-title {
                &:hover {
                    color: #ffffff;
                }

                &:active {
                    background-color: transparent;
                }
            }

            .ant-menu-submenu {
                .title {
                    display: flex;
                    align-content: center;

                    .title-box {
                        position: relative;

                        .dot {
                            position: absolute;
                            top: 50%;
                            right: -8px;
                            width: 6px;
                            height: 6px;
                            background-color: #4ce4f9;
                            border-radius: 50%;
                            transform: translateY(-90%);
                        }
                    }

                    img {
                        width: 50px;
                        margin-left: 10px;
                    }
                }

                .ant-menu-submenu-arrow {
                    &::after,
                    &::before {
                        background: #ffffff !important;
                    }
                }
            }

            .ant-menu-submenu-active {
                .ant-menu-submenu-arrow {
                    &::before {
                        background: #ffffff !important;
                    }

                    &::after {
                        background: #ffffff !important;
                    }
                }
            }

            .ant-menu-submenu-open {
                .title {
                    color: white;
                    display: flex;
                    align-content: center;

                    img {
                        width: 50px;
                        margin-left: 10px;
                    }
                }

                .ant-menu-submenu-arrow {
                    color: white;

                    &::before {
                        background: #253bff !important;
                    }

                    &::after {
                        background: #253bff !important;
                    }
                }
            }

            .ant-menu-submenu-selected {
                color: white;
            }

            .ant-menu-item {
                color: white;

                &:active {
                    background-color: transparent;
                }

                &.ant-menu-item-disabled {
                    &.ant-menu-item-selected {
                        background-color: transparent;
                    }

                    > a {
                        color: rgba(255, 255, 255, 0.4) !important;
                    }
                }

                &.ant-menu-item-selected {
                    background-color: rgba(255, 255, 255, 0.1);

                    &::after {
                        display: none;
                    }

                    > a {
                        color: white;
                    }
                }

                > a {
                    color: rgba(255, 255, 255, 0.6);
                }
            }

            .title {
                font-size: 14px;
            }
        }

        .lang-item {
            height: 43px;
            display: flex;
            align-items: center;
            padding-left: 35px;
        }

        .lang-en {
            position: absolute;
            right: 40px;
            top: 0;
        }

        .lang-selected {
            .icon-check {
                visibility: visible !important;
            }
        }
    }

    .rotate-menu-enter-active,
    .rotate-menu-leave-active {
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .rotate-menu-enter,
    .rotate-menu-leave-to {
        transform: rotateX(-90deg);
    }
}
.message {
    position: fixed;
    top: 120px;
    right: 40px;
    cursor: pointer;
    padding: 12px;
    border-radius: 50%;
    /* border: 1px solid rgba(255, 255, 255, 0.40); */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.24) 100%);
    .icon-box {
        width: 24px;
        height: 24px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0%;
            right: -5px;
            width: 6px;
            height: 6px;
            background-color: #4ce4f9;
            border-radius: 50%;
            /* transform: translateY(-90%); */
        }
    }
}
.Faucet-entrance-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: rgba(1, 1, 1, 0.8);
}

.dot-box {
    display: flex;
    align-items: flex-start;

    span {
        position: relative;

        .dot {
            position: absolute;
            top: 50%;
            right: -10px;
            width: 6px;
            height: 6px;
            background-color: #4ce4f9;
            border-radius: 50%;
            transform: translateY(-90%);
        }
    }
}

.Faucet-entrance {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    padding: 60px;
    padding-bottom: 0;

    width: 986px;
    background: url('https://s3.us-west-1.amazonaws.com/agi.love/img/Faucetentrance1.webp') no-repeat;
    background-size: 100% 100%;
    border-radius: 15px;

    .iconguanbi {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }

    .faucet-bg {
        width: 458px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .Faucet-entrance-bg {
        position: relative;
        z-index: 9;

        p {
            margin-bottom: 10px;
        }

        p:nth-of-type(1) {
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 1px;
        }

        p:nth-of-type(2) {
            font-size: 36px;
            color: #4ce4f9;
            font-weight: bold;
            margin-bottom: 30px;
        }

        p:nth-of-type(3) {
            font-size: 16px;
            margin-bottom: 50px;
            color: rgba(255, 255, 255, 0.7);
        }

        p:nth-of-type(4) {
            cursor: pointer;
            text-decoration: underline;
            font-size: 16px;
            margin-bottom: 50px;
            padding-top: 60px;
            padding-left: 50px;
            color: rgba(255, 255, 255, 0.7);
        }

        .c-button {
            width: 324px;
            height: 84px;
            font-size: 30px;
            padding: 0 40px;
            border-radius: 50px;
        }
    }

    @screen md {
        width: 333px;
        height: unset !important;
        padding: 30px;

        .faucet-bg {
            width: 302px;
            height: 357px;
            bottom: unset;
            top: 10%;
        }

        .Faucet-entrance-bg {
            p:nth-of-type(1) {
                font-size: 36px;
            }

            p:nth-of-type(2) {
                font-size: 36px;
            }

            p:nth-of-type(3) {
                display: flex;
                flex-direction: column;
                text-align: left;
                font-size: 12px;
                color: #fff;
            }

            .c-button {
                margin-top: 50px;
                width: 168px;
                height: 44px;
                font-size: 14px;
                padding: 0 5px 0 20px;
                border-radius: 50px;

                .arrow {
                    width: 38px;
                    height: 38px;
                    min-width: 38px;
                }
            }

            p:nth-of-type(4) {
                padding: 20px 20px 0;
                margin-bottom: 10px;
            }
        }
    }
}

.ant-select-dropdown.plac-dropdown {
    background-color: #111924;
    border-radius: 10px;

    .ant-select-dropdown-menu-item {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 600;
        line-height: 20px;
        padding: 10px 20px;

        &.ant-select-dropdown-menu-item-active,
        &:hover {
            background-color: #192431;
            border-radius: 10px;
            color: white;
        }
    }

    .ant-select-dropdown-menu-item-selected {
        background-color: #192431;
        border-radius: 10px;
        color: white;
    }
}

.ant-dropdown.header-menu {
    background-color: #111924;
    border-radius: 10px;
    min-width: 124px;

    .ant-dropdown-menu {
        padding: 0;
        background-color: #111924;
        border-radius: 10px;
        box-shadow: none;

        .ant-dropdown-menu-item {
            font-weight: 600;
            line-height: 20px;
            padding: 10px 20px;
            border-radius: 10px;

            &:hover {
                background: linear-gradient(90deg, #203dfc 0%, #33c2f2 100%);
            }

            &.ant-dropdown-menu-item-disabled {
                color: rgba(255, 255, 255, 0.2);

                &:hover {
                    background: transparent !important;
                }

                > a {
                    color: rgba(255, 255, 255, 0.2) !important;
                    cursor: not-allowed;
                }
            }

            &:not(.ant-dropdown-menu-item-disabled):hover {
                background-color: #192431;
                border-radius: 10px;

                > a {
                    color: white;
                }
            }

            > a {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}

.popover-Box {
    display: none;
    overflow: hidden;
    transition: all 0.3s;
    min-width: 320px;
    position: absolute;
    right: 0;
    top: 32px;

    @screen md {
        min-width: 320px;
        right: -50px;
    }

    .popover-card {
        padding: 20px;
        border-radius: 12px;
        border: 1px solid #2e2a41;
        background: linear-gradient(0deg, rgba(26, 26, 29, 0.5) 0%, rgba(26, 26, 29, 1) 100%), rgba(255, 255, 255, 0.03);
        box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(5px);
    }

    .popover-head {
        .left {
            img {
                width: 28px;
                height: 28px;
            }

            span {
                color: #fff;
                text-align: right;

                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 142.857% */
            }
        }

        .right {
            display: flex;
            padding: 4px 0px;
            align-items: center;
            justify-content: center;
            // border-radius: 100px;
            // border: 0.5px solid #C9C9C9;

            img {
                margin-left: 25px;
                width: 20px;
                height: 20px;
            }

            span {
                color: #c9c9c9;
                text-align: right;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
            }
        }
    }

    .balance {
        padding: 20px 0;
        color: #fff;

        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .balance-list-head {
        .left {
            color: #fff;

            font-size: 12px;
            font-style: normal;
            font-weight: 510;
            line-height: 120%;
            /* 14.4px */
        }

        .right {
            display: flex;
            padding: 4px 12px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            border-radius: 40px;
            /* border: 0.5px solid rgba(255, 255, 255, 0.30); */
            background: linear-gradient(90deg, #031bde 0%, #4c7cf9 100%);

            color: var(--White, #fff);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 14.4px */
            text-transform: capitalize;
        }
    }

    .balance-list {
        ul {
            padding: 7px 0;

            li {
                dl {
                    margin: 0;

                    dt {
                        margin: 0;
                    }

                    dd {
                        margin: 0;
                    }
                }
                width: 100%;
                padding: 7px 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .left {
                    padding-top: 8px;

                    img {
                        border-radius: 100%;
                        overflow: hidden;
                        width: 24px;
                        height: 24px;
                    }
                }

                .center {
                    flex: 1;
                    text-align: left;
                    padding: 0 10px;

                    dt {
                        color: var(--White, #fff);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    dd {
                        padding-top: 5px;
                        color: var(--White, #fff);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 120%;
                        /* 16.8px */
                    }
                }

                .right {
                    dt {
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .balance-box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .item-left {
                        width: 24px;
                        img {
                            width: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }
                    .item-right {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        .ust-bal,
                        .icon-bal {
                            width: 100%;
                            span {
                                color: #fff;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 120%;
                            }
                        }
                        .ust-bal {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .icon-bal {
                            span {
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }

    .points-box {
        ul {
            padding: 6px 0;
            margin: 0;

            li {
                padding: 6px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }
}
</style>
<style>
.ant-popover {
    min-width: 320px;

    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        background: unset;
    }
}
.mask {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background: #000000a8;
}
</style>
